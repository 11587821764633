<template>
  <!--[main]-->
  <div class="main">
    <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
    <section class="section">
      <div class="row">
        <div class="col">
          <Panel type="sheet">
            <template v-slot:headline>{{ isEdit ? '写真編集' : '写真登録' }}</template>
            <template v-slot:body>
              <div class="form">
                <section class="form-section">
                  <FormRow :required="!isEdit">
                    <template v-slot:label>カテゴリー選択</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div v-if="isEdit" class="form-group">
                          <div class="form-group-item">{{ formData.category ? formData.category.name : '' }}</div>
                        </div>
                        <div v-else class="form-select">
                          <select
                            class="form-control form-select-input"
                            :class="{ 'is-error': errors['categoryId'] }"
                            name="categoryId"
                            v-model="formData.categoryId"
                          >
                            <option value="">選択してください</option>
                            <option v-for="category in categories" :key="category.id" :value="category.id">
                              {{ category.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="!isEdit">
                    <template v-slot:label>写真をアップロード</template>
                    <template v-slot:subLabel>画像推奨サイズ：1125px × 750px</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div v-if="isEdit">
                          <div class="fileUpload-item p-0">
                            <div class="fileUpload-set is-upload">
                              <label class="fileUpload-label">
                                <img class="fileUpload-thumb" :src="formData.imageUrl" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <ul v-else class="fileUpload">
                          <li class="fileUpload-item">
                            <Fileupload @uploaded="uploadImage" @removed="deleteImage" :loading="isUploadLoading" :isError="errors['imageId']" />
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>コメント</template>
                    <template v-slot:labelNote>（100文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          class="form-control form-textarea"
                          :class="{ 'is-error': errors['comment'] }"
                          name="comment"
                          v-trim
                          v-maxlength
                          maxlength="100"
                          v-model="formData.comment"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                </section>
              </div>
            </template>
          </Panel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import nav from '@/mixins/nav/gallery';
import error from '@/mixins/plugin/error';
//component
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';
export default {
  data() {
    return {
      formData: {
        categoryId: '',
        imageId: '',
        imageUrl: '',
        comment: '',
      },
      isUploadLoading: false,
    };
  },
  props: {
    pageName: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [nav, error],
  components: {
    FormRow,
    Fileupload,
  },
  validations() {
    const formData = {
      comment: { maxLength: maxLength(100) },
    };
    if (!this.isEdit) {
      formData.categoryId = { selectRequired: required };
      formData.imageId = { selectRequired: required };
    }
    return { formData };
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      categories: 'gallery/categoryList',
      galleryDetail: 'gallery/galleryDetail',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    id() {
      return this.$route.params?.id;
    },
    formatData() {
      return {
        categoryId: this.formData.categoryId,
        image: this.formData.imageId,
        comment: this.formData.comment,
      };
    },
  },
  methods: {
    handleSubmit: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'gallery');
      } else {
        let result;
        if (!this.isEdit) {
          result = await this.$store.dispatch('gallery/createGallery', {
            subdomain: this.subdomain,
            shopId: this.shopId,
            data: this.formatData,
          });
          if (result) {
            await this.$router.push({ name: 'ShopGalleryList' });
            this.$message.created('gallery');
          }
        } else {
          result = await this.$store.dispatch('gallery/updateGallery', {
            subdomain: this.subdomain,
            shopId: this.shopId,
            data: this.formatData,
            id: this.id,
          });
          if (result) {
            await this.$router.push({ name: 'ShopGalleryList' });
            this.$message.updated('gallery');
          }
        }
      }
    },
    async uploadImage(url) {
      this.isUploadLoading = true;
      this.formData.imageId = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.isUploadLoading = false;
    },
    deleteImage() {
      this.formData.imageId = '';
    },
    getCategories() {
      this.$store.dispatch('gallery/getCategories', {
        subdomain: this.subdomain,
        shopId: this.shopId,
      });
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('gallery/getGalleryDetail', {
          subdomain: this.subdomain,
          shopId: this.shopId,
          id: this.id,
        });
      }
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([this.getCategories(), this.getDetail()]).finally(() => {
      if (this.isEdit) {
        this.formData = {
          ...this.formData,
          ...this.galleryDetail,
          imageId: this.galleryDetail.image.id,
          imageUrl: this.galleryDetail.image.url,
          categoryId: this.galleryDetail.category.id,
        };
      }
      this.$loading.clear(loading);
    });
  },
};
</script>

<style></style>
